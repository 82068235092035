import React from 'react';
import gpt3Logo from '../../logo.svg';
import './footer.css';

const Footer = () => (
  <div className="dmt__footer section__padding">
    {/* <div className="dmt__footer-heading">
      <h1 className="gradient__text">Do you want to step in to the future before others</h1>
    </div>

    <div className="dmt__footer-btn">
      <p>Request Early Access</p>
    </div> */}

    <div className="dmt__footer-links">
      <div className="dmt__footer-links_logo">
        <img src={gpt3Logo} alt="gpt3_logo" />
        <p>Hayaliniz Gerçeğimizdir, <br /> Tüm Hakları Saklıdır</p>
      </div>
      <div className="dmt__footer-links_div">
        <h4>Links</h4>
        <p>Anasayfa</p>
        <p>Hizmetler</p>
        <p>Profil</p>
        <p>İletişim</p>
      </div>
      <div className="dmt__footer-links_div">
        <h4>Firma</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
        <p>İletişim</p>
      </div>
      <div className="dmt__footer-links_div">
        <h4>İletişim</h4>
        <p>Gölbaşı ANKARA</p>
        <p>0545 767 9667</p>
        <p>info@dmtbilisim.com</p>
      </div>
    </div>

    <div className="dmt__footer-copyright">
      <p>@2021 DMT Bilişim. Tüm Hakları Saklıdır.</p>
    </div>
  </div>
);

export default Footer;
