import React from 'react';
import { Footer, Partners, Features, Ndmt, Header } from './containers';
import { CTA, Navbar, Brand, Contact } from './components';
import './App.css';

function App() {
  return (
    <>
      <div className="App">
        <div className="gradient__bg">
          <Navbar />
          <Header />
        </div>
        <Brand />
        <Ndmt />
        <Features />
        <CTA />
        <Partners />
        <Contact />
        <Footer />
      </div>
    </>
  );
}

export default App;
