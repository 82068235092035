import React from 'react';
import Feature from '../../components/feature/Feature';
import './ndmt.css';

const Ndmt = () => (
  <div className="dmt__ndmt section__margin" id="ndmt">
    <div className="dmt__ndmt-feature">
      <Feature title="Neden DMT Bilişim" text="DMT Bilişim olarak, teknoloji dünyasında fark yaratan bir firmanın olmanın ötesinde, müşterilerimize sunduğumuz değer ve taahhütlerle öne çıkıyoruz." />
    </div>
    <div className="dmt__ndmt-heading">
      <h1 className="gradient__text">Olasılıkları sizin hayalleriniz doğrusunda genişletiyoruz</h1>
      <p>Ürünlerimizi inceleyin</p>
    </div>
    <div className="dmt__ndmt-container">
      <Feature title="Teknolojiye Hakimiyet" text="Sektördeki en yeni teknolojilere hakim bir ekiple çalışın. Yazılım dünyasında lider olmanın sorumluluğunu taşıyor ve kendimizi sürekli eğitiyoruz." />
      <Feature title="Size Özel Çözümler" text="Her müşterimiz benzersizdir. İhtiyaçlarınıza özel çözümler sunarak işinizi daha verimli ve rekabet avantajına sahip kılıyoruz." />
      <Feature title="Güven ve İnovasyon" text="Güvenilirlik ve inovasyon, temel değerlerimizdir. Müşteri memnuniyeti odaklı yaklaşımımızla uzun vadeli iş ortaklıkları kuruyoruz." />
    </div>
  </div>
);

export default Ndmt;
