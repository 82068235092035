import React from 'react';
import { html, css, javascript, react, nodejs } from './imports';
import './brand.css';

const Brand = () => (
  <div className="dmt__brand section__padding">
    <div>
      <img src={react} />
    </div>
    <div>
      <img src={nodejs} />
    </div>
    <div>
      <img src={html} />
    </div>
    <div>
      <img src={css} />
    </div>
    <div>
      <img src={javascript} />
    </div>
  </div>
);

export default Brand;
