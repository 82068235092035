import React from 'react';
import ai from '../../assets/ai.png';
import './header.css';

const Header = () => (
  <div className="dmt__header section__padding" id="home">
    <div className="dmt__header-content">
      <h1 className="gradient__text">DMT Bilişim<br /> İleri Teknoloji Yazılımları</h1>
      <p>DMT Bilişim olarak, geleceğin teknolojisini bugünden yaşatıyoruz. Web, mobil, sensör ve yapay zeka alanlarında uzman ekibimizle özgün çözümler sunuyoruz. Sizi, dijital dönüşümde bir adım öne taşımak için buradayız.</p>

      <div className="dmt__header-content__input">
        {/* <input type="email" placeholder="E-Posta adresiniz" /> */}
        <a href="#contacts"><button type="button">Bilgi Al</button></a>
      </div>
    </div>

    <div className="dmt__header-image">
      <img src={ai} />
    </div>
  </div>
);

export default Header;
