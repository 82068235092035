import React from 'react';
import partnersImage from '../../assets/partners.png';
import './partners.css';

const Partners = () => (
  <div className="dmt__partners section__padding" id="partners">
    <div className="dmt__partners-image">
      <img src={partnersImage} alt="partners" />
    </div>
    <div className="dmt__partners-content">
      <h4>Güçlü İş ortaklıkları</h4>
      <h1 className="gradient__text">İş ortaklarıyla yapılan işbirlikleri,  <br /> yenilik ve inovasyonu tetikler.</h1>
      <p> Rekabetçi dünyada başarılı olmak için işbirlikleri, işletmelerin potansiyelini en üst düzeye çıkarmak ve sürdürülebilir bir büyüme stratejisi oluşturmak için önemli bir araçtır. Güçlü iş ortaklıklarımızla sizlere daha hızlı, güvenli ve sürdürülebilir hizmetler sunuyoruz.</p>
    </div>
  </div>
);

export default Partners;
