import React from 'react';
import './contact.css';

function validateEmail(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

/* eslint-disable no-alert */
function submitForm() {
  const name = document.querySelector('input[type="text"]').value;
  const email = document.querySelector('input[type="email"]').value;
  const message = document.querySelector('textarea').value;
  if (name === '' || email === '' || message === '') {
    alert('Lütfen tüm alanları doldurunuz.');
    return;
  }
  if (!validateEmail(email)) {
    alert('Lütfen geçerli bir e-posta adresi giriniz.');
    return;
  }
  alert('Mesajınız başarıyla gönderildi.');
  document.querySelector('input[type="text"]').value = '';
  document.querySelector('input[type="email"]').value = '';
  document.querySelector('textarea').value = '';
}

function Contact() {
  return (
    <div className="dmt__contact" id="contacts">
      <form id="myForm" method="POST" action="mgonder.asp">
        <div className="dmt__contact-content">
          <p>Bizimle iletişime geçin</p>
          <h3>Size en kısa zamandadönüş yapalım.</h3>
          <input type="text" name="name" placeholder="Adınız" autoComplete="name" />
          <input type="email" name="email" placeholder="E-Postanız" autoComplete="email" />
          <textarea name="message" placeholder="Mesajınız" autoComplete="message" />
          <button type="submit" onClick={submitForm}>Mesajı Gönder</button>
        </div>
      </form>
    </div>
  );
}

export default Contact;
