import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'WEB UYGULAMALARI',
    text: 'Kullanıcı dostu, özelleştirilebilir ve güvenli web siteleri ile markanızı çevrimiçi platformlarda öne çıkarın.',
  },
  {
    title: 'MOBIL UYGULAMALAR',
    text: ' Android ,iOS ve Windows için özgün mobil uygulamalarla müşteri deneyimini artırın, iş süreçlerinizi optimize edin.',
  },
  {
    title: 'SENSOR TEKNOLOJİLERİ',
    text: 'Nesnelerin interneti (IoT) ve sensör teknolojileri ile gerçek zamanlı veri analizi yapın, daha akıllı ve verimli kararlar alın.',
  },
  {
    title: 'YAPAY ZEKA YAZILIMLARI',
    text: 'Makine öğrenimi ve derin öğrenme tekniklerini kullanarak iş süreçlerinizi optimize edin, veri madenciliği ile değerli bilgiler elde edin.',
  },
];

const Features = () => (
  <div className="dmt__features section__padding" id="features">
    <div className="dmt__features-heading">
      <h1 className="gradient__text">Teknoloji, günümüz iş dünyasında başarıya giden yolda kritik bir rol oynamaktadır.</h1>
      <p>Hizmetlerimiz Hakkında Bilgi Alın</p>
    </div>
    <div className="dmt__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
