import React from 'react';
import './cta.css';

const CTA = () => (
  <div className="dmt__cta">
    <div className="dmt__cta-content">
      <p>Dijital dönüşümünüzü hızlandırın</p>
      <h3>Yarını bugünden yaşamak için bizimle iletişime geçin.</h3>
    </div>
    <div className="dmt__cta-btn">
      <a href="#contacts"><button type="button">İletişim</button></a>
    </div>
  </div>
);

export default CTA;
